/* eslint-disable */
let initialized = false;
let debug = false;

/**
 * Warn
 * @param  {...any} args
 */
const warn = (...args) => {
    if (!debug) {
        return;
    }
    // eslint-disable-next-line no-console
    console.info(...['webgains-pixel]'].concat(args));
};

/**
 * Log
 * @param  {...any} args
 */
const log = (...args) => {
    if (!debug) {
        return;
    }
    // eslint-disable-next-line no-console
    console.info(...['[webgains-pixel]'].concat(args));
};

const verifyInit = () => {
    if (!initialized) {
        warn('Pixel not initialized before using call CustomPixel.init with required params');
    }
    return initialized;
};

//
const defaultOptions = {
    debug: false,
};

//
const WebGainsPixel = {
    init(masterTagId, options = defaultOptions) {
        if (initialized) {
            warn('Pixel script already initialized');
            return;
        }

        if (typeof masterTagId !== 'string' || masterTagId.trim() === '') {
            throw new Error('masterTagId is missing');
        }

        // Check if the script is already loaded
        const scriptId = 'webgains-pixel-script';
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = `https://analytics.webgains.io/309706/main.min.js`;
            script.type = 'text/javascript';
            script.defer = true;
            document.body.appendChild(script);
        }


        initialized = true;
        debug = options.debug;

        if (debug) {
            log('Custom pixel script initialized');
        }
    },

    remove() {
        const scriptId = 'webgains-pixel-script';
        const script = document.getElementById(scriptId);
        if (script) {
            document.body.removeChild(script);
            initialized = false;

            if (debug) {
                log('Custom pixel script removed');
            }
        } else {
            if (debug) {
                warn('Pixel script not found');
            }
        }
    },

    trackPurchase(amount, orderRef, currency, voucher = '', test = '0') {
        (function(src) {
            var o = 'ITCVRQ';
          window[o] = window[o] || function() {(window[o].q=window[o].q||[]).push(arguments)};
          window[o].l = 1 * new Date();
          var s = document.createElement('script');
          var f = document.getElementsByTagName('script')[0];
          s.async = 1;s.src = src;f.parentNode.insertBefore(s,f);
           })('https://analytics.webgains.io/309706/main.min.js');
          ITCVRQ('set', 'trk.programId', 309706);
          ITCVRQ('set', 'cvr', {
              value: `${Number(amount).toFixed(2)}`,
              currency: currency,
              language: 'ES_es',
              eventId: 1109120,
              orderReference: `${orderRef}`,
              comment: '',
              multiple: '',
              checksum: '',
              items: '',
              customerId:'',
              voucherId: voucher,
              location: document.location.href
          });
          ITCVRQ('conversion');

        if (debug) {
            log('Purchase tracked: ', ITCVRQ.Tracking.Sale);
        }
    },
};

export default WebGainsPixel;
