const getSelectedPlan = (planNumber: number) => {
    const isDestinationPlan = planNumber === 1;
    const isMultiDestinationPlan = planNumber === 2;
    const isGetawayPlan = planNumber === 3;
    const isSummerPlan = planNumber === 4;
    const isAllSurprisePlan = planNumber === 5;
    const isVipPlan = planNumber === 6;
    const isNationalPlan = planNumber === 7;
    const isAloyPlan = planNumber === 9;

    return {
        isDestinationPlan,
        isMultiDestinationPlan,
        isGetawayPlan,
        isSummerPlan,
        isAllSurprisePlan,
        isVipPlan,
        isNationalPlan,
        isAloyPlan,
    };
};

export default getSelectedPlan;
