import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import FacebookPixel from 'react-facebook-pixel';
import BingTracking from 'react-tracking-bing';
import TaboolaTracking from 'react-tracking-taboola';
import TiktokPixel from '../utils/tiktokPixel';
import RedditPixel from '../utils/redditPixel';
import PinterestPixel from '../utils/pinterestPixel';
import ShareASalePixel from '../utils/shareASalePixel';
import { useAppSelector, useAppDispatch } from './useRedux';
import WebGainsPixel from '@/utils/webGainsPixel';

import type { FromTypes } from '@/components/funnel/payment/Payment';

declare global {
    interface Window {
        dataLayer: any;
    }
}

interface IFromProps {
    from: FromTypes;
}

let initialized = false;

function useTracking() {
    const planId = useAppSelector((state) => state.plan.currentPlan);
    const totalPrice = useAppSelector((state) => state.booking.totalPrice);
    const bookingTransactionId = useAppSelector((state) => state.booking.transactionId);
    const giftTransactionId = useAppSelector((state) => state.gift.transactionId);
    const { existingDiscount, discountCode } = useAppSelector((state) => state.discount);
    const giftCardQuantity = useAppSelector((state) => state.gift.giftCardAmount);
    const giftCardPrice = useAppSelector((state) => state.gift.giftCardPrice);
    const currencyName = useAppSelector((state) => state.booking.currency.name);
    const location = useLocation();
    const isProductionEnv = import.meta.env.VITE_APP_ENV === 'prod';
    const trackEvents = isProductionEnv;
    /* const trackEvents = isConsent && isProductionEnv; */

    useEffect(() => {
        if (trackEvents && !initialized) {
            initialized = true;
            initiateTracking();
            sendPageView();
        }
    }, []);

    useEffect(() => {
        if (trackEvents) sendPageView();
    }, [location]);

    const initiateTracking = () => {
        ReactGA.initialize('G-1N1P9WE6Z3'); // Google Analytics
        ReactGA.gtag('config', 'AW-11128341892'); // Google Ads
        TagManager.initialize({ gtmId: 'GTM-5WW97R4F' }); // Google Tag Manager Id
        TiktokPixel.init('CIKM6S3C77U3LS6JSLV0');
        RedditPixel.init('t2_s3vnv2h8');
        PinterestPixel.init('2613756555752');
        ShareASalePixel.init('85831'); // {{advertiserId}} from AWIN panel
        WebGainsPixel.init('309706');
        FacebookPixel.init('138250463456068');
        BingTracking.initialize('343062955');
        TaboolaTracking.initialize('1597291');
        hotjar.initialize(3427551, 6);
    };

    const onConsentTracking = () => {
        /* localStorage.setItem('cookiesAcceptance', 'true');

        if (isProductionEnv) {
            initiateTracking();
            sendPageView();
        } */
    };

    const onDeclineTracking = () => {
        /* localStorage.removeItem('cookiesAcceptance'); */
    };

    const sendPageView = () => {
        /* ReactGA.send({ hitType: 'page_view', page: location.pathname }); */
        ReactGA.gtag('event', 'page_view', { page: location.pathname });
        FacebookPixel.pageView();
        PinterestPixel.track('pagevisit');
        TaboolaTracking.trackPageview();
        BingTracking.trackPageview();
        if (hotjar.initialized()) {
            hotjar.stateChange(location.pathname);
        }
    };

    const trackStartFunnelEvent = () => {
        if (!trackEvents) return;
        // Google Analytics
        ReactGA.gtag('event', 'add_to_cart');
        // Google Ads - Add to cart conversion
        ReactGA.gtag('event', 'conversion', { send_to: 'AW-11128341892/0yrTCOGKr5wYEISLtLop' });
        PinterestPixel.track('AddToCart');
        TaboolaTracking.trackEvent('AddToCart');
        BingTracking.trackEvent('AddToCart');
    };

    const trackArrivalToPaymentPage = ({ from }: IFromProps) => {
        if (!trackEvents) return;

        const isFromBooking = from === 'booking';
        const transactionId = isFromBooking ? bookingTransactionId : giftTransactionId;

        PinterestPixel.track('lead', {
            order_id: transactionId,
            currency: currencyName,
            value: Number(totalPrice),
            lead_type: 'Arrival to payment page',
        });
    };

    const trackPurchaseEvent = ({ from }: IFromProps) => {
        if (!trackEvents) return;

        const isFromBooking = from === 'booking';
        const isFromGiftCard = from === 'giftCard';
        const isFromGiftTravel = from === 'giftTravel';

        const getTrackingEventName = () => {
            if (isFromGiftCard) return 'Gift card payment';
            if (isFromGiftTravel) return 'Gift travel payment';
            return 'Booking payment';
        };

        const getProductName = () => {
            if (isFromGiftCard) return 'Gift Card';
            if (isFromGiftTravel) return `Gift Travel: Plan ${planId}`;
            return `Booking: Plan ${planId}`;
        };

        const getProductCategory = () => {
            if (isFromGiftCard) return 'Gift Card';
            if (isFromGiftTravel) return `Gift Travel`;
            return `Booking`;
        };

        const eventName = getTrackingEventName();
        const transactionId = isFromBooking ? bookingTransactionId : giftTransactionId;

        // ShareASale
        ShareASalePixel.remove();
        ShareASalePixel.trackPurchase(totalPrice, transactionId, currencyName, discountCode);
        ShareASalePixel.init('85831'); // {{advertiserId}} from AWIN panel pass to ShareASalePixel

        // WebGains Partner
        WebGainsPixel.remove();
        WebGainsPixel.trackPurchase(totalPrice, transactionId, currencyName, discountCode);
        WebGainsPixel.init('309706');

        // Google Analytics
        ReactGA.gtag('event', 'purchase', {
            transaction_id: transactionId,
            value: Number(totalPrice),
            currency: currencyName,
            ...(existingDiscount && { coupon: discountCode }),
            items: [
                {
                    item_name: getProductName(),
                    item_id: getProductName(),
                    item_category: getProductCategory(),
                    price: isFromGiftCard ? `${giftCardPrice}` : `${totalPrice}`,
                    quantity: isFromGiftCard ? giftCardQuantity : 1,
                },
            ],
        });
        // Google Ads - Purchase conversion
        ReactGA.gtag('event', 'conversion', {
            send_to: 'AW-11128341892/b7_VCJ32iJUYEISLtLop',
            value: Number(totalPrice),
            currency: currencyName,
            transaction_id: transactionId,
        });
        // Google Ads (2) - Purchase conversion
        ReactGA.gtag('event', 'conversion', {
            send_to: 'AW-831213902/Kq8cCO72tJMBEM6irYwD',
            value: Number(totalPrice),
            currency: currencyName,
            transaction_id: transactionId,
        });
        FacebookPixel.track('Purchase', {
            content_name: eventName,
            content_ids: [transactionId],
            value: Number(totalPrice),
            currency: currencyName,
        });
        TiktokPixel.track('CompletePayment', {
            content_id: transactionId,
            currency: currencyName,
            value: Number(totalPrice),
        });
        PinterestPixel.track('checkout', {
            order_id: transactionId,
            currency: currencyName,
            value: Number(totalPrice),
        });
        RedditPixel.track('Purchase');
        TaboolaTracking.trackEvent('Purchase');
        BingTracking.trackEvent('purchase', 'purchase', transactionId, Number(totalPrice));
    };

    const ShareASalePixelImg = ({ isFromBooking }: { isFromBooking: boolean }) => {
        const orderID = isFromBooking ? bookingTransactionId : giftTransactionId;
        const amount = Number(totalPrice).toFixed(2);
        const merchantID = '156839';

        return (
            <img
                id="_SHRSL_img_1"
                src={`https://www.shareasale.com/sale.cfm?tracking=${orderID}&amount=${amount}&merchantID=${merchantID}&transtype=sale&couponcode=${discountCode}&currency=${currencyName}`}
                width="1"
                height="1"
                alt=""
            />
        );
    };

    return {
        onConsentTracking,
        onDeclineTracking,
        trackPurchaseEvent,
        trackStartFunnelEvent,
        trackArrivalToPaymentPage,
        ShareASalePixel,
        ShareASalePixelImg,
        WebGainsPixel,
    };
}

export default useTracking;
